import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const MetaTags = ({
  title,
  description,
  canonical,
  schema,
  robots,
  googleAnalyticsCode,
}) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonical} />
        <meta name="description" content={description} />
        <meta name="robots" content={robots} />
        <script type="application/ld+json">{schema}</script>
        {/* {googleAnalyticsCode && (
          <script data-rh="true">
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleAnalyticsCode}');`}
          </script>
        )} */}
      </Helmet>
    </HelmetProvider>
  );
};

export default MetaTags;
